export const questions = [
	{
		id: 1,
		question: `What is Dollar buy sell Corner? / Dollar buy sell Corner কী`,
		answer: `Dollar Buy Sell Corner is an e-currency exchanger with its primary function to help users exchange one type of e-currency to another.`,
		answerB: `Dollar Buy Sell Corner হল একটি ই-কারেন্সি এক্সচেঞ্জার, যার প্রাথমিক কাজ ব্যবহারকারীদের এক ধরনের ই-মুদ্রা অন্যের সাথে বিনিময় করতে সাহায্য করে। এটি একটি অনলাইন ভিত্তিক dollar buy sell এর একটি বিশ্বস্ত প্লাটফর্ম। সাধ্যানুযায়ী গ্রাহককে সেবা প্রদান করাই আমাদের প্রধান লক্ষ্য।`,
	},
	{
		id: 2,
		question: `How long does it take to process an order ? / কত সময়ের মধ্যে আপনারা লেনদেন সম্পন্ন করেন ?`,
		answer: `Usually, we take 5 minutes and a maximum of 60 minutes to complete order. If you have any urgency you can contact us over live chat, email, FB fan page inbox, WhatsApp.`,
		answerB: `সাধারণত, আমরা অর্ডার সম্পন্ন করতে 5 মিনিট এবং সর্বোচ্চ 60 মিনিট সময় নিয়ে থাকি। আপনার যদি কোন জরুরী থাকে তাহলে আপনি আমাদের সাথে লাইভ চ্যাট, ইমেল, এফবি ফ্যান পেজ ইনবক্স, হোয়াটসঅ্যাপ অথবা ফোন নাম্বারে যোগাযোগ করতে পারেন।`,
	},
	{
		id: 3,
		question: `Is my transactions and transactions details secured ? / আমার লেনদেন এবং লেনদেনের বিবরণ কি সুরক্ষিত আছে?`,
		answer: `Yes, our service is fully secured. All transactions and transactions details are totally secured by authority.`,
		answerB: `হ্যাঁ, আমাদের পরিষেবা সম্পূর্ণ সুরক্ষিত। সমস্ত লেনদেন এবং লেনদেনের বিবরণ সম্পূর্ণভাবে কর্তৃপক্ষ দ্বারা সুরক্ষিত।`,
	},
	{
		id: 4,
		question: `why was my transactions canceled / rejected ? / কেন আমার লেনদেন বাতিল/প্রত্যাখ্যাত হয়েছে?`,
		answer: `Maybe you sent an incorrect amount or  you did not complete the dollar buy sell transaction within 1 hour, and our system did not recognize your payment as a correct one.`,
		answerB: `আপনি একটি ভুল অর্থ পাঠিয়েছেন বা আপনি ১ ঘন্টার মধ্যে dollar buy sell লেনদেন সম্পন্ন করেননি এবং আমাদের সিস্টেম আপনার অর্থপ্রদানকে সঠিক হিসাবে স্বীকৃতি দেয়নি।`,
	},
	{
		id: 5,
		question: `where can I leave my feedback ? /  আমি কোথায় আমার মতামত দিতে পারি ?`,
		answer: `Before you leave a feedback you need to be a verified user and must complete a dollar buy sell transaction and then When you click confirm transaction then you see a feedback option . then you can leave your feedback .`,
		answerB: `আপনি একটি প্রতিক্রিয়া দেওয়ার আগে আপনাকে একজন যাচাইকৃত ব্যবহারকারী হতে হবে এবং অবশ্যই একটি dollar buy sell লেনদেন সম্পূর্ণ করতে হবে এবং তারপর আপনি যখন নিশ্চিত লেনদেন ক্লিক করবেন তখন আপনি একটি প্রতিক্রিয়া বিকল্প দেখতে পাবেন। তারপর আপনি আপনার মতামত দিতে পারেন।`,
	},
	{
		id: 6,
		question: `what should i do if i have not found the answer to my question? / আমি যদি আমার প্রশ্নের উত্তর না পাই তাহলে আমার কি করা উচিত?`,
		answer: `Then please feel free to contact with us via whatsapp, messenger, email,  phone call.`,
		answerB: `তাহলে অনুগ্রহ করে আমাদের সাথে হোয়াটসঅ্যাপ, মেসেঞ্জার, ইমেইল, ফোন কলের মাধ্যমে নির্দ্বিধায় যোগাযোগ করুন।`,
	},
	{
		id: 7,
		question: `If i found a bug / problem in your website or have an idea / suggestion about your website, what should i do ? / আমি যদি আপনার ওয়েবসাইটে একটি বাগ/সমস্যা খুঁজে পাই বা আপনার ওয়েবসাইট সম্পর্কে ধারণা/পরামর্শ পাই, তাহলে আমার কী করা উচিত?`,
		answer: `It would be great for us . If you find anything like this , please feel free to share with us via whatsapp, messenger, email,  phone call. We will be very gratefull for your cooperation.`,
		answerB: `এটা আমাদের জন্য সম্মানজনক হবে। আপনি যদি এরকম কিছু খুঁজে পান, অনুগ্রহ করে আমাদের সাথে হোয়াটসঅ্যাপ, মেসেঞ্জার, ইমেল, ফোন কলের মাধ্যমে নির্দ্বিধায় শেয়ার করুন। আমরা আপনার সহযোগিতার জন্য খুবই কৃতজ্ঞ হব।`,
	},
];
